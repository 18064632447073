import React from "react"
import Layout from "../components/layout"
import Markdown from "react-markdown"
import AboutPerson from "../components/about-person"

export const query = graphql`
  query AboutPageQuery {
    strapiAbout {
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
      title
      description
      people {
        name
        jobTitle
        about
        email
        image {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default function AboutUs({ data, location }) {
  const seo = { ...data.strapiAbout.seo, url: location.pathname }
  return (
    <Layout seo={seo}>
      <div className="container mx-auto px-4 mb-12  mt-12 lg:px-12">
        <h1 className="font-sans font-bold text-4xl md:text-5xl lg:text-6xl mb-10 text-left">
          About Us
        </h1>

        <div class="grid grid-cols-12 mb-12 sm:mb-12">
          <div class="col-span-12 sm:col-span-9">
            <Markdown
              source={data.strapiAbout.description}
              escapeHtml={false}
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 mb-24 px-4 lg:px-12">
        <div class="grid grid-cols-12 gap-8">
          {data.strapiAbout.people.map((value, index) => {
            return <AboutPerson person={value} key={index} />
          })}
        </div>
      </div>
    </Layout>
  )
}
