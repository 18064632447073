import React from "react"
import Img from "gatsby-image"
import Markdown from "react-markdown"

export default function AboutPerson({ person }) {
  return (
    <div class="col-span-12 md:col-span-6 mb-8">
      <div class="grid grid-cols-12">
        <div class="col-span-4 sm:col-span-5">
          <Img
            className="border-4 rounded-full border-green-500"
            fluid={person.image.childImageSharp.fluid}
            alt={person.name}
          />
        </div>
        <div class="col-span-7 ml-4 items-center grid  text-2xl ">
          <div>
            <div className="font-bold text-green-500 lg:text-3xl">
              {person.name}
            </div>
            {person.jobTitle}
          </div>
        </div>
        <div className="col-span-12 mt-8">
          <Markdown
            source={person.about}
            className="markdown"
            escapeHtml={false}
          />
        </div>
        {!!person.email && (
          <div className="col-span-12  mt-4">
            <span className="font-bold">Email: </span>
            {person.email}
          </div>
        )}
      </div>
    </div>
  )
}
